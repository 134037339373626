@import '../../../../styles/colors';

.jumbo {
  background-color: #F8F8F8;
  position: relative;
}

.left {
  h1 {
    font-size: 1.75rem;
    text-transform: uppercase;
  }

  .text {
    font-size: 0.875;
  }
}
.form {
  margin-top: 2rem;
  > div {
    width: 100%;
  }

  @media(max-width: 992px) {
    > div {
      margin-top: 5px;
    }
  }

  @media(min-width: 992px) and (max-width: 1200px) {
    .triggerSearch {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  select {
    width: 100% !important;
  }
}
.right {
  margin-bottom: -8rem;
  margin-top: -4rem;

  @media(max-width: 992px) {
    position: absolute;
    bottom: 0;
    margin: 0;
  }
  @media(max-width: 768px) {
    background-color: $red;
    margin: 0;
    margin-top: 5px;
    position: relative;
  }

}


.backRight {
  position: absolute;
  left: 55%;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: $red;


  &:after {
    content: ' ';
    background-color: $red;
    position: absolute;
    left: -50px;
    top:0;
    bottom: 0;
    transform: skewX(-10deg);
    width: 100px;
  }
  @media(max-width: 768px) {
    position: absolute;
    background-image: none;
    top: 100%;
    &:after {
      display: none;
      content: '';
    }
  }
}
