@import '../../../../styles/colors';

.jumbo {
  display: flex;
  flex-direction: row;
  position: relative;
  background-image: url("../../../../images/Mask Group 6.png");
  background-position: right;
  background-repeat: no-repeat;
  background-size: 50% 100%;
  background-color: $red;

  &:after {
    content: ' ';
    width: 150px;
    height: 100%;
    transform: skewX(-10deg);
    top: 0;
    left: calc(50% - 50px);
    position: absolute;
    background-color: $red;
  }
  @media(max-width: 990px) {
    &:after {
      width: 100px;
      left: calc(50% -50px);
    }
  }
  @media(max-width: 768px) {
    background-position: center;
    background-size: 200% 100%;
    &:after {
      display: none;
      content: '';
    }
  }
}

.title {
  font-family: 'Exo 2';
  font-size: 1.5rem;
  text-transform: uppercase;
}

.card {
  z-index: 10;
}

.body {
  padding: 2.5rem 1.875rem 1rem 1.875rem;
  hr {
    margin-left: -1.875rem;
    margin-right: -1.875rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.title {
  font-family: 'Exo 2' !important;
  text-transform: uppercase;
  font-size: 1.5rem !important;
}

.indication {
  padding: 0;
}

.text {
  margin: 0;

}

.form {
  margin-bottom: 2rem;
  .input {
    flex: 1;
    input {
      width: 100%;
      margin-right: 5px;
    }
  }


  @media(max-width: 1200px) {
    div {
      width: 100%;
    }
    .input input {
      width: 100% !important;
      margin-right: auto;
    }

    .triggerSearch {
      margin-top: 5px;
    }
  }
}


.car {
  position: absolute;
  bottom: -4rem;
  left: -2rem;
  z-index: 1000;
  @media(max-width: 768px) {
    position: relative;
    bottom: unset;
    left: unset;
    margin-top: 2rem;
    margin-bottom:-2rem;
  }
}
