.iconButton {
  vertical-align: middle;
  padding-right: 2.375rem;
  font-weight: bold;

  svg {
    font-size: 1.125rem;
    margin-right: 1rem;
  }
}
