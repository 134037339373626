.car__loader {
  &--container {
    position: relative;
  }
  &--spinner {
    position: fixed;
    top: 50%;
  }
  position: absolute;
  z-index: 5;
  height: 96%;
  opacity: 0.6;
  min-height: 70vh;
}