.navbar-light {
  .navbar-nav {
    .nav-link {
      @extend .nav-link;
      color: #000;
      text-transform: uppercase;
      border-bottom: solid 3px transparent;
      padding-left: 0.25rem;
      padding-right: 0.25rem;
      margin-left: 2rem;
      margin-right: 2rem;
      padding-bottom: 1rem;
      transition: border 0.5s;
      &:last-of-type {
        margin-right: auto;
      }
      &.active {
          color: $primary;
      }
      @include hover-focus() {
        border-bottom: solid 3px $red;
        color: $primary;
      }
    }
  }
}

.card {
  .h5 { font-size: 1rem;
    font-family: 'Roboto';
  }
  .h6 {
    font-size: 0.875rem;
    font-family: 'Roboto';
  }
}



