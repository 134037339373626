@import '../../../styles/colors';
.list {
  margin-left: -7px !important;
  margin-right: -7px !important;

}

.cardContainer {
  padding: 7px !important;
}

.card {
  height: 100%;
}

.title {
  a {
    font-size: 1rem;
    font-family: 'Roboto';
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
}

.table {
  margin: 0;
  padding: 0;
  td {
    color: $muted;
    padding: 0;
    line-height: 1.375rem;

  }
}
.label {
  font-size: 0.75rem;
}
.value {
  font-weight: normal;
}

.image {
  flex: 1;
}

.body {
  flex: unset;
}

.chart {
  text-align: right;

  img {
    width: 2.125rem;
  }
}
