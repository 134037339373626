.card {
  height: 200px;
  max-height: 200px;
  display: flex !important;
  flex: 1;

  .image {
    padding: 1rem;
    width: 100%;
    height: 100%;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

}
