.car__container--no-padding {
  padding: 0px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.car__layout--footer {
  font-size: 12px;
}
