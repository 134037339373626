@import '~styles/colors.scss';

.section {
  .title {
    border-bottom: solid 3px $red;
    display: inline-block;
    padding-bottom: 1rem;
    & + hr {
      margin-top: 0;
      margin-bottom: 3.125rem;
    }
  }
  h5 {
    margin: 0;
    text-transform: uppercase;
  }

  .children {
    margin-bottom: 3.125rem;
  }
}

