.search__div--content {
  border: 1px solid rgba(0, 0, 0, 0.125);
  padding: 0.75rem 1.5rem;
}

.nox-badge__span--small {
  font-size: 80%;
  .badge {
    font-weight: 400 !important;
  }
}