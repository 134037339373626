/* Third Party Imports */

//@import "~bootstrap/scss/bootstrap";
@import './colors';

$enable-rounded: false;
$input-btn-font-size: 0.875rem;
$input-btn-padding-y: 0.6875rem;
$input-btn-padding-x: 1rem;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px
) !default;


@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

$input-font-weight: $font-weight-bold;
$input-border-color: #ededed;
$card-border-color: #ededed;
$custom-select-font-weight: $font-weight-bold;
//$jumbotron-bg: $red;
$h1-font-size: 1.5rem;
$h2-font-size: 1.3125rem;
$h5-font-size: 1.3125rem;
$headings-font-family: "Exo 2";

@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/custom-forms";

$custom-select-bg-size: 0.85rem 0.85rem;
$custom-select-padding-y: 1rem;
$custom-select-padding-x: 0.75rem;
$custom-select-bg: #F8F8F8;
$custom-select-indicator: url('data:image/svg+xml,<svg fill="#{$custom-select-indicator-color}" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path></svg>');

$custom-select-background: escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size;

$custom-dropdown-bg-size: 1rem 1rem;

$custom-dropdown-background: escape-svg($custom-select-indicator) no-repeat right 0rem center / $custom-dropdown-bg-size;

.custom-select {
  @extend .custom-select;
  background: $custom-select-bg $custom-select-background;
  color: #A2A2A2;
}

.dropdown-toggle {
  @extend .dropdown-toggle;
  border: none;
  padding: $dropdown-padding-y #{$dropdown-padding-x + 1.5rem} $dropdown-padding-y $dropdown-padding-x!important;
  background: $custom-dropdown-background;
  background-position-y: 0.75rem;
  margin-left: 2rem !important;
  margin-right: 2rem !important;
  padding-bottom: 1rem !important;
  &:after {
    content: none;
  }
}

@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/pagination";
@import "../../node_modules/bootstrap/scss/badge";
@import "../../node_modules/bootstrap/scss/jumbotron";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/list-group";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/toasts";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/carousel";
@import "../../node_modules/bootstrap/scss/spinners";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/print";

@import './overwrites';
@import './layout';
@import './vehicleListing';
@import './vehicleModel';
@import './loader';
@import './helpers';
@import './search';


@import '../pages/next/styles';
